<template>
  <transition name="fade">
    <div v-if="isShow" class="ui-context-menu">
      <div v-for="(arrayItem, indx) in items" :key="indx">
        <div v-if="indx" class="hr"/>
        <div
          class="item"
          v-for="(item, indexItem) in arrayItem"
          :key="indexItem"
          @click="item.action"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'ContextMenu',
    props: ['isShow', 'items'],
  };
</script>

<style lang="scss" scoped>
  .ui-context-menu {

    width: 312px;
    box-shadow: 0 2px 21px 0 rgba(219, 219, 219, 0.5);
    background-color: #ffffff;
    border-radius: 4px;
    position: absolute;

    padding-top: 16px;
    padding-bottom: 8px;

    z-index: 10;

    .item {
      padding: 16px;
      font-size: 16px;
      color: #383838;
      cursor: pointer;
      transition: all ease 0.1s;
    }
    .item:hover {
      color: black;
    }

    .hr {
      margin:0px 16px;
      height: 1px;
      background: #D9D9D9;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */
  {
    opacity: 0;
  }
</style>
