<template>
  <div class="top_header_bg">
    <div class="top_header container-for-default-layout">
<!--      <div class="logo" @click="home"></div>-->
      <img src="@/assets/images/logo.png" class="logo" @click="home">
      <div class="user" v-click-outside="clickOutside">
        <div class="user_name">{{ username }}</div>
        <img :src="avatarUrl" alt="avatar" class="user_avatar" @click="toggleUserMenu" />
        <context-menu class="user_menu" :is-show="userMenuShow" :items="userMenuItems"></context-menu>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import defaultAvatar from '@/assets/images/empty-user-avatar.svg';
import mapFields from '../utils/mapFields';
import ContextMenu from './ContextMenu';
import config from '../config';

export default {
  name: 'TopHeader',
  components: { ContextMenu },
  directives: { ClickOutside },
  data() {
    return {
      // avatarUrl: undefined,
      shadow: false,
      userMenuShow: false,
      userMenuItems: [
        // [
        //   {
        //     label: 'Change password',
        //     action: () => this.$router.push({ path: '/change-password' })
        //   }
        // ],
        [
          {
            label: 'Log Out',
            action: () => this.logout()
          }
        ]
      ]
    };
  },
  computed: {
    ...mapFields({
      email: 'auth.user.email',
      photoPath: 'auth.user.photoSmall.key'
    }),
    username() {
      const { user } = this.$store.state.auth;
      if (user && user?.firstName && user?.lastName) {
        return `${user?.firstName} ${user?.lastName}`;
      }
      return this.email;
    },
    avatarUrl() {
      if (this.photoPath) {
        return `${config.backendUrl}/${this.photoPath}?token=${localStorage['feathers-jwt']}`;
      }

      return defaultAvatar;
    }
  },
  methods: {
    toggleUserMenu() {
      this.userMenuShow = !this.userMenuShow;
    },
    clickOutside() {
      this.userMenuShow = false;
    },
    home() {
      // this.$router.push({ path: '/' });
    },
    async logout() {
      await this.$store.dispatch('auth/logout');
      this.$router.push({ name: 'login' });
    }
  }
};
</script>

<style scoped lang="scss">
.top_header_bg {
  background: #fafafa;

  .top_header {
    height: 56px;
    display: flex;
    justify-content: space-between;

    position: relative;

    .logo {
      //width: 138px;
      height: 34px;
      //background-image: url('~@/assets/images/logo_4x.png');
      //background-repeat: no-repeat;
      //background-size: contain;
      cursor: pointer;
    }

    .user {
      display: inline-flex;
      align-items: center;
    }

    .user_name {
      font-size: 14px;
      color: #383838;
      margin-right: 16px;
    }

    .user_avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      // background: url("~@/assets/images/empty-user-avatar.svg");
      cursor: pointer;
    }

    .user_menu {
      right: 18px;
      top: 50px;
    }
  }
}
</style>
