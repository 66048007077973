<template>
  <div class="page-layout">
    <TopHeader></TopHeader>
    <div class="container page-container">
      <router-view class="router-view" />
    </div>
  </div>
</template>

<script>
  import TopHeader from '@/components/TopHeader'

  export default {
    components: { TopHeader }
  }
</script>

<style lang="scss">
  .page-layout {
    min-height: 100vh;
    display:flex;
    flex-direction: column;
  }
  .page-container {
    padding-bottom: 64px;
    flex-grow: 1;
    display:flex;
    flex-direction: column;
  }
  .router-view {
    flex-grow: 1;
  }
</style>
